import classNames from 'classnames'
import { FC, useEffect, useState } from 'react'

const transitionClasses = {
  ease: 'ease-[cubic-bezier(0.1,0,0,1)]',
  transition: 'transition-all motion-reduce:transition-none',
  duration: 'duration-[3s]',
}

const defaultTransition = classNames(
  transitionClasses.ease,
  transitionClasses.transition,
  transitionClasses.duration,
)

const elementBaseClass = 'absolute pointer-events-none -z-10'

export const BackgroundWrapper: FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  return (
    <div
      className={'pointer-events-none absolute inset-0 -z-10 overflow-hidden'}
      aria-hidden
    >
      {children}
    </div>
  )
}

export const BackgroundElements = ({
  elementBottomLeft = 'shape-and-icon',
  elementBottomRight = 'star-bottom',
  fadeIn = false,
}: {
  elementBottomLeft?: 'shape' | 'shape-and-icon'
  elementBottomRight?: 'star-bottom' | 'star-right'
  fadeIn?: boolean
}) => {
  const [_areElementsVisible, setAreElementsVisible] = useState(!fadeIn)

  const [showTransition, setShowTransition] = useState(fadeIn)

  // if transition is deactivated, elements are always visible
  const areElementsVisible = !showTransition ? true : _areElementsVisible //

  useEffect(() => {
    let mounted = true

    setAreElementsVisible(true)

    setTimeout(() => {
      if (mounted) {
        // disable transitions after the initial animation
        setShowTransition(false)
      }

      return () => {
        mounted = false
      }
    }, 3000)
  }, [])

  return (
    <>
      <StarIcon
        className={classNames(
          elementBaseClass,
          'left-[max(100vw-320px,800px)] top-[-1100px] h-auto w-[2000px] text-lavender-500',
          showTransition && defaultTransition,
          showTransition && 'delay-[0.2s]',
          areElementsVisible
            ? 'opacity-1 rotate-[11deg]'
            : 'translate-y-[50px] rotate-[4deg] opacity-0',
        )}
        strokeWidth={3.5}
      />
      <StarIcon
        className={classNames(
          elementBaseClass,
          'h-auto w-[2600px] text-green-500',
          showTransition && defaultTransition,
          showTransition && 'delay-[0.5s]',
          areElementsVisible
            ? // element visible classes
              elementBottomRight === 'star-bottom'
              ? 'left-[max(100vw-1350px,100px)] top-[max(100vh-400px,67%)] rotate-[60deg]'
              : 'left-[max(100vw-280px,800px)] top-[max(100%-1400px,-300px)] rotate-[24deg]'
            : // element invisible classes
              elementBottomRight === 'star-bottom'
              ? 'translate-y-[150px] rotate-[55deg] scale-105 opacity-0'
              : 'translate-y-[150px] rotate-[30deg] opacity-0',
        )}
        strokeWidth={3}
      />
      <div
        className={
          'absolute left-[min(90vw-1000px,0px)] top-[max(100%-500px,500px)] h-auto w-[2000px]'
        }
      >
        <BottomLeftShape
          className={classNames(
            elementBaseClass,
            'h-auto w-full',
            showTransition && defaultTransition,
            'delay-[0.6s]',
            areElementsVisible
              ? 'opacity-1 translate-x-0'
              : '-translate-x-20 opacity-0',
          )}
          hasDropShadow={elementBottomLeft === 'shape-and-icon'}
        />
        <BottomLeftIcon
          className={classNames(
            elementBaseClass,
            'h-auto w-full',
            showTransition && transitionClasses.transition,
            showTransition && transitionClasses.ease,
            showTransition && 'origin-bottom-left delay-[0.8s]',
            showTransition && transitionClasses.duration,
            areElementsVisible && elementBottomLeft === 'shape-and-icon'
              ? 'opacity-1 scale-100'
              : 'scale-95 opacity-0',
          )}
        />
      </div>
    </>
  )
}

export const StarIcon = ({
  className,
  strokeWidth = 9,
}: {
  className?: string
  strokeWidth?: number
}) => {
  return (
    <svg
      width='264'
      height='264'
      viewBox='0 0 264 264'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames(className, 'pointer-events-none -z-10')}
    >
      <path
        d='M126.848 7.20922L121.948 12.267L105.137 29.6037C104.284 30.4998 102.601 30.978 101.404 30.6648L71.1611 23.0351C68.4663 22.3518 65.7195 23.9849 65.0624 26.6721L64.9755 27.0662L57.4581 56.9066C57.1447 58.1038 55.9378 59.356 54.7546 59.6923L24.7555 68.218C22.0737 68.9801 20.5304 71.7487 21.2925 74.4303L29.8178 104.428C30.154 105.611 29.7311 107.323 28.8701 108.192L7.10739 130.602C5.17476 132.6 5.22284 135.769 7.19495 137.709L29.5898 159.418C30.4859 160.271 30.9641 161.954 30.6508 163.151L23.0539 193.412C22.3705 196.107 24.0036 198.853 26.6909 199.51L56.9338 207.14C58.131 207.453 59.3833 208.66 59.7196 209.843L68.2448 239.841C69.007 242.523 71.7756 244.066 74.4574 243.304L104.457 234.778C105.64 234.442 107.351 234.865 108.221 235.726L130.616 257.434C132.614 259.367 135.783 259.319 137.716 257.32L159.433 234.952C160.287 234.056 161.97 233.578 163.167 233.891L193.402 241.494C196.097 242.178 198.844 240.545 199.501 237.857L207.132 207.616C207.445 206.418 208.652 205.166 209.835 204.83L239.834 196.304C242.516 195.542 244.059 192.773 243.297 190.092L234.772 160.094C234.436 158.911 234.859 157.199 235.72 156.33L257.437 133.961C259.37 131.963 259.322 128.794 257.35 126.854L234.955 105.146C234.059 104.292 233.58 102.609 233.894 101.412L241.445 71.3917L241.498 71.1778C242.181 68.4831 240.548 65.7364 237.861 65.0794L207.618 57.4497C206.421 57.1365 205.169 55.9296 204.832 54.7465L196.307 24.7486C195.545 22.067 192.776 20.5238 190.095 21.286L160.095 29.8116C158.912 30.1479 157.201 29.725 156.331 28.8641L133.974 7.08774C131.975 5.15522 128.807 5.20336 126.874 7.20174L126.848 7.20922Z'
        stroke='currentColor'
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export const BottomLeftShape = ({
  className,
  hasDropShadow = false,
}: {
  className?: string
  hasDropShadow?: boolean
}) => (
  //Star right side
  <svg
    viewBox='0 0 1440 404'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <g filter={hasDropShadow ? 'url(#filter0_d_1231_5022)' : undefined}>
      <mask
        id='mask0_1231_5022'
        mask='mask-type:alpha'
        maskUnits='userSpaceOnUse'
        x='0'
        y='-15'
        width='536'
        height='420'
      >
        <rect
          x='536'
          y='404.001'
          width='536'
          height='419'
          rx='20'
          transform='rotate(180 536 404.001)'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_1231_5022)'>
        <path
          d='M-1343.1 1783.67L-1060.41 1620.36C-1049 1613.76 -1031 1613.76 -1019.59 1620.36L-736.895 1783.67C-711.686 1798.08 -679.575 1789.68 -664.87 1764.46L-662.769 1760.86L-501.613 1481.67C-495.011 1470.56 -479.406 1461.25 -466.201 1461.25L-139.988 1461.25C-110.878 1461.25 -87.47 1437.54 -87.47 1408.72L-87.47 1082.39C-87.47 1069.48 -78.4671 1053.57 -67.0631 1047.27L215.635 883.957C240.843 869.548 249.546 837.125 234.841 812.208L71.5847 529.415C64.9824 518.007 64.9824 499.995 71.5846 488.587L234.841 205.793C249.246 180.576 240.843 148.454 215.635 133.744L-67.0632 -29.5677C-78.4672 -36.1722 -87.4701 -51.7832 -87.4701 -64.692L-87.4701 -391.015C-87.4701 -420.135 -111.178 -443.551 -139.988 -443.551L-466.202 -443.551C-479.106 -443.551 -495.011 -452.557 -501.614 -463.965L-664.87 -746.758C-679.575 -771.976 -711.686 -780.381 -736.895 -765.971L-1019.59 -602.66C-1031 -596.055 -1049 -596.055 -1060.41 -602.66L-1343.1 -765.971C-1368.31 -780.381 -1400.42 -771.976 -1415.13 -746.758L-1578.39 -463.965C-1584.99 -452.857 -1600.59 -443.551 -1613.5 -443.551L-1940.01 -443.551C-1969.12 -443.551 -1992.53 -420.135 -1992.53 -391.015L-1992.53 -64.6918C-1992.53 -51.783 -2001.53 -35.8719 -2012.94 -29.5676L-2295.63 133.744C-2320.84 148.454 -2329.55 180.576 -2314.84 205.793L-2151.58 488.587C-2144.98 499.695 -2144.98 518.007 -2151.58 529.415L-2313.64 810.107L-2314.84 812.208C-2329.25 837.426 -2320.84 869.548 -2295.63 884.258L-2012.94 1047.57C-2001.53 1054.17 -1992.53 1069.78 -1992.53 1082.69L-1992.53 1409.02C-1992.53 1438.14 -1968.82 1461.55 -1940.01 1461.55L-1613.5 1461.55C-1600.59 1461.55 -1584.69 1470.56 -1578.09 1481.97L-1414.83 1764.76C-1400.42 1789.98 -1368.01 1798.38 -1342.8 1783.97'
          fill='#FFF4D6'
        />
      </g>
    </g>

    <defs>
      <filter
        id='filter0_d_1231_5022'
        x='-4'
        y='-14.9992'
        width='250'
        height='427'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1231_5022'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1231_5022'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

export const BottomLeftIcon = ({ className }: { className?: string }) => (
  //Star right side
  <svg
    viewBox='0 0 1440 404'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <path
      d='M174 297.001H268'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M193 259.001V297.001'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M136 156.334C136 153.859 136.989 151.485 138.75 149.734C140.511 147.984 142.899 147.001 145.389 147.001H295.611C298.101 147.001 300.489 147.984 302.25 149.734C304.011 151.485 305 153.859 305 156.334V249.667C305 252.143 304.011 254.517 302.25 256.267C300.489 258.017 298.101 259.001 295.611 259.001H145.389C142.899 259.001 140.511 258.017 138.75 256.267C136.989 254.517 136 252.143 136 249.667V156.334Z'
      fill='#FFC933'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M249 259.001V297.001'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M105 111.751C105 109.695 105.817 107.724 107.27 106.271C108.723 104.817 110.695 104.001 112.75 104.001H159.25C161.305 104.001 163.277 104.817 164.73 106.271C166.183 107.724 167 109.695 167 111.751V158.251C167 160.306 166.183 162.277 164.73 163.731C163.277 165.184 161.305 166.001 159.25 166.001H112.75C110.695 166.001 108.723 165.184 107.27 163.731C105.817 162.277 105 160.306 105 158.251V111.751Z'
      fill='#FFF4D6'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M155.323 137.756L135.95 156.942L128.47 149.534M112.67 128.602C112.649 125.878 113.489 123.217 115.071 120.999C116.653 118.781 118.896 117.121 121.479 116.255C124.062 115.389 126.852 115.362 129.452 116.177C132.051 116.993 134.326 118.61 135.95 120.796C137.019 119.376 138.368 118.191 139.916 117.316C141.463 116.441 143.174 115.895 144.942 115.712C146.709 115.529 148.496 115.712 150.19 116.251C151.883 116.79 153.447 117.673 154.784 118.844C156.121 120.016 157.201 121.45 157.958 123.059C158.715 124.667 159.132 126.414 159.182 128.19C159.233 129.967 158.916 131.735 158.252 133.384C157.588 135.032 156.591 136.526 155.323 137.771'
      fill='#FFF4D6'
    />
    <path
      d='M155.323 137.756L135.95 156.942L128.47 149.534M112.67 128.602C112.649 125.878 113.489 123.217 115.071 120.999C116.653 118.781 118.896 117.121 121.479 116.255C124.062 115.389 126.852 115.362 129.452 116.177C132.051 116.993 134.326 118.61 135.95 120.796C137.019 119.376 138.368 118.191 139.916 117.316C141.463 116.441 143.174 115.895 144.942 115.712C146.709 115.529 148.496 115.712 150.19 116.251C151.883 116.79 153.447 117.673 154.784 118.844C156.121 120.016 157.201 121.45 157.958 123.059C158.715 124.667 159.132 126.414 159.182 128.19C159.233 129.967 158.916 131.735 158.252 133.384C157.588 135.032 156.591 136.526 155.323 137.771'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M112.704 136.278H117.87L123.036 144.027L128.202 128.53L130.785 136.278H138.534'
      fill='#FFF4D6'
    />
    <path
      d='M112.704 136.278H117.87L123.036 144.027L128.202 128.53L130.785 136.278H138.534'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M191 202.661C191 206.556 191.767 210.413 193.258 214.011C194.748 217.61 196.933 220.88 199.687 223.634C202.441 226.388 205.711 228.573 209.31 230.063C212.908 231.554 216.765 232.321 220.66 232.321C224.555 232.321 228.412 231.554 232.011 230.063C235.609 228.573 238.879 226.388 241.633 223.634C244.387 220.88 246.572 217.61 248.063 214.011C249.553 210.413 250.32 206.556 250.32 202.661C250.32 198.766 249.553 194.909 248.063 191.311C246.572 187.712 244.387 184.442 241.633 181.688C238.879 178.934 235.609 176.749 232.011 175.259C228.412 173.768 224.555 173.001 220.66 173.001C216.765 173.001 212.908 173.768 209.31 175.259C205.711 176.749 202.441 178.934 199.687 181.688C196.933 184.442 194.748 187.712 193.258 191.311C191.767 194.909 191 198.766 191 202.661Z'
      fill='#FFF4D6'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M210.773 196.482C210.773 199.104 211.815 201.619 213.669 203.473C215.523 205.327 218.038 206.368 220.66 206.368C223.282 206.368 225.797 205.327 227.651 203.473C229.505 201.619 230.547 199.104 230.547 196.482C230.547 193.86 229.505 191.345 227.651 189.491C225.797 187.637 223.282 186.595 220.66 186.595C218.038 186.595 215.523 187.637 213.669 189.491C211.815 191.345 210.773 193.86 210.773 196.482Z'
      fill='#FFF4D6'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M200.887 224.888C201.726 222.392 203.443 220.204 205.783 218.649C208.123 217.094 210.961 216.254 213.877 216.255H227.437C230.356 216.254 233.198 217.096 235.54 218.654C237.882 220.213 239.598 222.406 240.434 224.906'
      fill='#FFF4D6'
    />
    <path
      d='M200.887 224.888C201.726 222.392 203.443 220.204 205.783 218.649C208.123 217.094 210.961 216.254 213.877 216.255H227.437C230.356 216.254 233.198 217.096 235.54 218.654C237.882 220.213 239.598 222.406 240.434 224.906'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M298.682 183.343H291.341C289.394 183.343 287.527 184.116 286.15 185.493C284.773 186.87 284 188.737 284 190.684V234.731C284 236.678 284.773 238.546 286.15 239.922C287.527 241.299 289.394 242.072 291.341 242.072H328.047C329.994 242.072 331.862 241.299 333.238 239.922C334.615 238.546 335.388 236.678 335.388 234.731V190.684C335.388 188.737 334.615 186.87 333.238 185.493C331.862 184.116 329.994 183.343 328.047 183.343H320.706'
      fill='#FFF4D6'
    />
    <path
      d='M298.682 183.343H291.341C289.394 183.343 287.527 184.116 286.15 185.493C284.773 186.87 284 188.737 284 190.684V234.731C284 236.678 284.773 238.546 286.15 239.922C287.527 241.299 289.394 242.072 291.341 242.072H328.047C329.994 242.072 331.862 241.299 333.238 239.922C334.615 238.546 335.388 236.678 335.388 234.731V190.684C335.388 188.737 334.615 186.87 333.238 185.493C331.862 184.116 329.994 183.343 328.047 183.343H320.706'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M298.682 183.342C298.682 181.395 299.455 179.528 300.832 178.151C302.209 176.774 304.076 176.001 306.023 176.001H313.364C315.311 176.001 317.178 176.774 318.555 178.151C319.932 179.528 320.705 181.395 320.705 183.342C320.705 185.289 319.932 187.156 318.555 188.533C317.178 189.91 315.311 190.683 313.364 190.683H306.023C304.076 190.683 302.209 189.91 300.832 188.533C299.455 187.156 298.682 185.289 298.682 183.342Z'
      fill='#FFF4D6'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M298.682 216.378H298.718H298.682Z' fill='#FFF4D6' />
    <path
      d='M298.682 216.378H298.718'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M298.682 227.39H298.718H298.682Z' fill='#FFF4D6' />
    <path
      d='M298.682 227.39H298.718'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M309.694 223.72L313.364 227.39L324.376 216.378' fill='#FFF4D6' />
    <path
      d='M309.694 223.72L313.364 227.39L324.376 216.378'
      stroke='#1E1E1E'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
